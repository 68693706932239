import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown, Badge, Button, Collapse, Image, Alert } from "react-bootstrap";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { GoGraph } from "react-icons/go";
import { FaMessage } from "react-icons/fa6";
import { MdFeedback } from "react-icons/md";
import { RiDragMove2Fill } from "react-icons/ri";
import { FaBars, FaBell, FaUsers, FaBook, FaTachometerAlt, FaChevronUp, FaChevronDown, FaClipboardCheck, FaComment, FaPen, FaDatabase, FaFileAlt, FaHome, FaChartLine, FaBookOpen, FaRoute, FaLightbulb, FaCog, FaGraduationCap, FaFileArchive, FaPencilAlt, FaMountain, FaGamepad, FaHandshake, FaStar, FaClipboardList, FaRobot, FaCheckSquare, FaTheaterMasks, FaBookmark, FaFolder, FaBullseye, FaGlobe, FaFile, FaListAlt, FaCommentDots, FaCalendarAlt, FaPaperPlane, FaChartBar, FaTasks, FaThumbsUp, FaEllipsisV } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { LOGOUT } from "../redux/action";
import { MANAGE_USER_LOGOUT } from "../api/server.js";
import { postRequestMethod } from "../api/api.js";
import images from "../images";
const Logo = images.logo;

const MainLayout = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [types, setTypes] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [variant, setVariant] = useState("danger");

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const { user, role } = useSelector((state) => state.LoginReducer);

  // console.log("User Details : ", user);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCollapse, setShowCollapse] = useState(false);
  const handleToggle1 = () => setOpen1(!open1);
  const handleToggle2 = () => setOpen2(!open2);
  const handleToggle3 = () => setOpen3(!open3);
  const handleToggle4 = () => setOpen4(!open4);
  const handleToggle5 = () => setOpen5(!open5);
  const handleToggle6 = () => setOpen6(!open6);
  const handleToggle7 = () => setOpen7(!open7);
  const handleToggle = () => {
    setOpen(!open);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
  };
  const handleDropdownToggle = () => setShowDropdown(!showDropdown);
  const handleCollapseToggle = () => setShowCollapse(!showCollapse);

  const dispatch = useDispatch();

  const logout = () => {
    try {
      postRequestMethod(MANAGE_USER_LOGOUT, "", { dummy: "dummy" });
    } catch (error) {
      setSnackMessage("Something went wrong.");
      setOpenSnackbar(true);
      setVariant("danger");
      return console.error("Error during logout:", error);
    }
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("type");
    sessionStorage.removeItem("studentDetail");
    dispatch({ type: LOGOUT });
    navigate("/login");
  };

  const [showNestedList, setShowNestedList] = useState(false);

  const handleToggleNestedList = () => {
    setShowNestedList(!showNestedList);
  };

  useEffect(() => {
    const auth = sessionStorage.getItem("user");
    if (auth) {
      const myObject = JSON.parse(auth);
      // Now you can use the object
      setTypes(myObject);
      // setAdmintype(myObject);
    }
  }, []);
  return (
    <>
      <Alert show={openSnackbar} variant={variant} onClose={handleCloseSnackbar} dismissible className="position-fixed" style={{ top: "60px", right: "10px" }}>
        {snackMessage}
      </Alert>
      <Navbar expand="lg" fixed="top" className="w-100" style={{ backgroundColor: "#7335b7" }}>
        <Container fluid>
          <Button
            // variant="primary"
            onClick={handleToggle}
            className="me-2 d-flex align-items-center"
            style={{
              backgroundColor: "#7335b7",
              color: "#ffffff",
              border: "2px solid",
              height: "40px",
              width: "50px",
            }}
          >
            <FaBars />
          </Button>
          <Navbar.Brand href="#" className="text-light tw-flex tw-gap-4 tw-justify-center tw-items-center">
            <img src={Logo} alt="Logo" height="50" width="50" className="d-inline-block align-top" />
            <strong>Happy Plum Language Learning</strong>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <FaEllipsisV />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              {role === "Parent" ? (
                <Nav.Link onClick={() => navigate("/dashboard/notifications")} className="d-flex align-items-center justify-content-center">
                  <FaBell size={20} className="text-white" />
                </Nav.Link>
              ) : null}

              <Nav.Link href="#">
                <Badge pill className="d-inline-flex align-items-center">
                  <Image src={Logo} alt="" roundedCircle className="mr-2 mb-0" width={35} height={35} />
                  {/* <p className="mx-2 mb-0">{types?.email}</p> */}
                  <p className="mx-2 mb-0">{types?.fullName}</p>
                </Badge>
              </Nav.Link>

              <NavDropdown
                title={
                  <>
                    <Badge pill className="d-inline-flex align-items-center">
                      <h5 roundedcircle="true" className="mr-0 py-0">
                        <FaCog size={15} />
                      </h5>
                      {/* Settings */}
                    </Badge>{" "}
                  </>
                }
                id="collasible-nav-dropdown"
                align="end"
              >
                <NavDropdown.Item onClick={() => navigate("/dashboard/profile")}>Profile</NavDropdown.Item>
                <NavDropdown.Item href="#">My Account</NavDropdown.Item>
                <NavDropdown.Item href="#" onClick={logout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="d-flex h-100 py-5">
        <div
          className={`sidebar text-white ${open ? "open" : "closed"} `}
          style={{
            backgroundColor: "#7335b7",
            minHeight: "100vh",
            width: `${open ? "16%" : "3%"}`,
          }}
        >
          <Nav className="flex-column py-4" style={{ position: "fixed" }}>
            <Nav.Link onClick={() => navigate("/dashboard")} className="text-white d-flex align-items-center py-3">
              <FaStar className="me-2" size={25} />
              <h5>{open && `${role} Dashboard`}</h5>
            </Nav.Link>
            {role === "Student" ? (
              <>
                <Nav.Item>
                  <Nav.Link onClick={() => navigate("/dashboard")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard" ? "active" : ""}`}>
                    <FaHome className="me-2" size={25} />
                    {open && "Home"}
                  </Nav.Link>

                  <Nav.Link onClick={() => navigate("/dashboard/real-life-scenario")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/real-life-scenario" ? "active" : ""}`}>
                    <FaGlobe className="me-2" size={25} />
                    {open && "Real Life Scenario"}
                  </Nav.Link>

                  <Nav.Link onClick={() => navigate("/dashboard/lessons")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/lessons" ? "active" : ""}`}>
                    <FaBookOpen className="me-2" size={25} />
                    {open && "Lessons"}
                  </Nav.Link>

                  <Nav.Link onClick={handleToggle1} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/handleToggle1" ? "active" : ""}`}>
                    <FaChartBar className="me-2" size={25} onClick={handleToggle} />
                    {open && (
                      <>
                        {"Goals"} {open1 ? <FaChevronUp /> : <FaChevronDown />}
                      </>
                    )}
                  </Nav.Link>

                  <Collapse in={open1}>
                    <div className="px-4">
                      <Nav.Link onClick={() => navigate("/dashboard/goal")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/goal" ? "active" : ""}`}>
                        <FaFile className="me-2" size={25} />
                        {"Learning Goals"}
                      </Nav.Link>
                      <Nav.Link onClick={() => navigate("/dashboard/set-goal")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/set-goal" ? "active" : ""}`}>
                        <FaListAlt className="me-2" size={25} />
                        {"Set Goal"}
                      </Nav.Link>
                      <Nav.Link onClick={() => navigate("/dashboard/goal-progress")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/goal-progress" ? "active" : ""}`}>
                        <FaChartLine className="me-2" size={25} />
                        {"Goal Progress"}
                      </Nav.Link>
                    </div>
                  </Collapse>

                  <Nav.Link onClick={handleToggle2} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/handleToggle2" ? "active" : ""}`}>
                    <FaGamepad className="me-2" size={25} onClick={handleToggle} />
                    {open && (
                      <>
                        {"Games"} {open2 ? <FaChevronUp /> : <FaChevronDown />}
                      </>
                    )}
                  </Nav.Link>

                  <Collapse in={open2}>
                    <div className="px-4">
                      <Nav.Link onClick={() => navigate("/dashboard/games/drag-and-match")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/games/drag-and-match" ? "active" : ""}`}>
                        <RiDragMove2Fill className="me-2" size={25} />
                        {"Drag and Match"}
                      </Nav.Link>
                    </div>
                  </Collapse>

                  <Nav.Link onClick={() => navigate("/dashboard/student-performance-stats")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/student-performance-stats" ? "active" : ""}`}>
                    <GoGraph className="me-2" size={25} />
                    {open && "Performance"}
                  </Nav.Link>

                  <Nav.Link onClick={() => navigate("/dashboard/cultural-highlights")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/cultural-highlights" ? "active" : ""}`}>
                    <FaTheaterMasks className="me-2" size={25} />
                    {open && "Cultural Highlights"}
                  </Nav.Link>

                  <Nav.Link onClick={() => navigate("/dashboard/rubric")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/rubric" ? "active" : ""}`}>
                    <FaCheckSquare className="me-2" size={25} />
                    {open && "Rubric"}
                  </Nav.Link>

                  <Nav.Link onClick={() => navigate("/dashboard/homework")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/homework" ? "active" : ""}`}>
                    <FaBook className="me-2" size={25} />
                    {open && "Homework"}
                  </Nav.Link>

                  <Nav.Link onClick={() => navigate("/dashboard/review-sheet")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/review-sheet" ? "active" : ""}`}>
                    <FaStar className="me-2" size={25} />
                    {open && "Review Sheet"}
                  </Nav.Link>

                  <Nav.Link onClick={() => navigate("/dashboard/student-curriculum")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/student-curriculum" ? "active" : ""}`}>
                    <FaBullseye className="me-2" size={25} />
                    {open && "Curriculum"}
                  </Nav.Link>

                  <Nav.Link onClick={() => navigate("/dashboard/student-chat")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/student-chat" ? "active" : ""}`}>
                    <FaMessage className="me-2" size={25} />
                    {open && "Message"}
                  </Nav.Link>

                  <Nav.Link onClick={() => navigate("/dashboard/flash-cards")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/flash-cards" ? "active" : ""}`}>
                    <FaBookmark className="me-2" size={25} />
                    {open && "Flash cards"}
                  </Nav.Link>

                  <Nav.Link onClick={() => navigate("/dashboard/Test-assessments")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/Test-assessments" ? "active" : ""}`}>
                    <FaClipboardCheck className="me-2" size={25} />
                    {open && "Test/Assessment"}
                  </Nav.Link>

                  <Nav.Link onClick={() => navigate("/dashboard/project")} className={`nav-link text-white d-flex align-items-center ${location.pathname === "/dashboard/project" ? "active" : ""}`}>
                    <FaFolder className="me-2" size={25} />
                    {open && "Project"}
                  </Nav.Link>
                </Nav.Item>
              </>
            ) : role === "Teacher" ? (
              <Nav.Item>
                <Nav.Link onClick={() => navigate("/dashboard")} className="text-white d-flex align-items-center">
                  <FaTachometerAlt className="me-2" />
                  {open && "Home"}
                </Nav.Link>
                <Nav.Link onClick={handleToggle1} className="text-white d-flex align-items-center">
                  <FaUsers className="me-2" onClick={handleToggle} />
                  {open && (
                    <>
                      {"Classes"} {open1 ? <FaChevronUp /> : <FaChevronDown />}
                    </>
                  )}
                </Nav.Link>
                <Collapse in={open1}>
                  <div className="px-2">
                    <Nav.Link onClick={() => navigate("/dashboard/class-list")} className="text-white d-flex align-items-center">
                      <FaPen className="me-2" />
                      {open && <>{"Class List"} </>}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/class-overview")} className="text-white d-flex align-items-center">
                      <FaClipboardCheck className="me-2" />
                      {"Class Overview"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/attendance-tracking")} className="text-white d-flex align-items-center">
                      <FaComment className="me-2" />
                      {"Attendance Tracking"}
                    </Nav.Link>
                  </div>
                </Collapse>
                <Nav.Link onClick={handleToggle2} className="text-white d-flex align-items-center">
                  <FaChartLine className="me-2" onClick={handleToggle} />
                  {open && (
                    <>
                      {"Student"} {open2 ? <FaChevronUp /> : <FaChevronDown />}
                    </>
                  )}
                </Nav.Link>
                <Collapse in={open2}>
                  <div className="px-2">
                    <Nav.Link onClick={() => navigate("/dashboard/student-list")} className="text-white d-flex align-items-center">
                      <FaTachometerAlt className="me-2" />
                      {"Student List"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/student-profile")} className="text-white d-flex align-items-center">
                      <FaDatabase className="me-2" />
                      {"Student Profile"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/performance-graphs")} className="text-white d-flex align-items-center">
                      <FaFileAlt className="me-2" />
                      {"Performance Graphs"}
                    </Nav.Link>
                  </div>
                </Collapse>
                <Nav.Link onClick={handleToggle3} className="text-white d-flex align-items-center">
                  <FaBookOpen className="me-2" onClick={handleToggle} />
                  {open && (
                    <>
                      {"Assignments"} {open3 ? <FaChevronUp /> : <FaChevronDown />}
                    </>
                  )}
                </Nav.Link>
                <Collapse in={open3}>
                  <div className="px-2">
                    <Nav.Link onClick={() => navigate("/dashboard/assignment-manager")} className="text-white d-flex align-items-center">
                      <FaRoute className="me-2" />
                      {"Assignment Manager"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/create-assignment")} className="text-white d-flex align-items-center">
                      <FaLightbulb className="me-2" />
                      {"Create Assignment"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/submission-review")} className="text-white d-flex align-items-center">
                      <FaCog className="me-2" />
                      {"Submission Review"}
                    </Nav.Link>
                  </div>
                </Collapse>

                <Nav.Link onClick={handleToggle4} className="text-white d-flex align-items-center">
                  <FaBook className="me-2" onClick={handleToggle} />
                  {open && (
                    <>
                      {"Resources"} {open4 ? <FaChevronUp /> : <FaChevronDown />}
                    </>
                  )}
                </Nav.Link>
                <Collapse in={open4}>
                  <div className="px-2">
                    <Nav.Link onClick={() => navigate("/dashboard/resource-library")} className="text-white d-flex align-items-center">
                      <FaGraduationCap className="me-2" />
                      {"Resource Library"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/upload-share")} className="text-white d-flex align-items-center">
                      <FaFileArchive className="me-2" />
                      {"Upload and Share"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/content-creation")} className="text-white d-flex align-items-center">
                      <FaPencilAlt className="me-2" />
                      {"Content Creation"}
                    </Nav.Link>
                  </div>
                </Collapse>

                <Nav.Link onClick={handleToggle5} className="text-white d-flex align-items-center">
                  <FaMountain className="me-2" onClick={handleToggle} />
                  {open && (
                    <>
                      {"Messages"} {open5 ? <FaChevronUp /> : <FaChevronDown />}
                    </>
                  )}
                </Nav.Link>
                <Collapse in={open5}>
                  <div className="px-2">
                    <Nav.Link onClick={() => navigate("/dashboard/inbox")} className="text-white d-flex align-items-center">
                      <FaGamepad className="me-2" />
                      {"inbox"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/compose-message")} className="text-white d-flex align-items-center">
                      <FaHandshake className="me-2" />
                      {"Compose Message"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/group-messages")} className="text-white d-flex align-items-center">
                      <FaStar className="me-2" />
                      {"Group Messages"}
                    </Nav.Link>
                  </div>
                </Collapse>

                <Nav.Link onClick={handleToggle6} className="text-white d-flex align-items-center">
                  <FaClipboardCheck className="me-2" onClick={handleToggle} />
                  {open && (
                    <>
                      {"Analytics"} {open6 ? <FaChevronUp /> : <FaChevronDown />}
                    </>
                  )}
                </Nav.Link>
                <Collapse in={open6}>
                  <div className="px-2">
                    <Nav.Link onClick={() => navigate("/dashboard/performance-overview")} className="text-white d-flex align-items-center">
                      <FaClipboardList className="me-2" />
                      {"Performance Overview"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/engagement-reports")} className="text-white d-flex align-items-center">
                      <FaRobot className="me-2" />
                      {"Engagement Reports"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/progress-tracking")} className="text-white d-flex align-items-center">
                      <FaCheckSquare className="me-2" />
                      {"Progress Tracking"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/custom-reports")} className="text-white d-flex align-items-center">
                      <FaCheckSquare className="me-2" />
                      {"Custom Reports"}
                    </Nav.Link>
                  </div>
                </Collapse>
              </Nav.Item>
            ) : role === "Parent" ? (
              <Nav.Item>
                {/* <Nav.Link onClick={() => navigate("/dashboard")} className="text-white d-flex align-items-center">
                      <FaTachometerAlt className="me-2" />
                      {open && "Home"}
                    </Nav.Link> */}
                <Nav.Link onClick={handleToggle1} className="text-white d-flex align-items-center">
                  <FaChartBar className="me-2" onClick={handleToggle} />
                  {open && (
                    <>
                      {"Overview and Progress Tracking"} {open1 ? <FaChevronUp /> : <FaChevronDown />}
                    </>
                  )}
                </Nav.Link>
                <Collapse in={open1}>
                  <div className="px-2">
                    <Nav.Link onClick={() => navigate("/dashboard/child-profile")} className="text-white d-flex align-items-center">
                      <FaFile className="me-2" />
                      {"Child Profile"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/progress-summary")} className="text-white d-flex align-items-center">
                      <FaListAlt className="me-2" />
                      {"Progress Summary"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/detailed-reports")} className="text-white d-flex align-items-center">
                      <FaChartLine className="me-2" />
                      {"Detailed Reports"}
                    </Nav.Link>
                  </div>
                </Collapse>
                <Nav.Link onClick={handleToggle3} className="text-white d-flex align-items-center">
                  <FaComment className="me-2" onClick={handleToggle} />
                  {open && (
                    <>
                      {"Goal Setting & Monitoring"} {open3 ? <FaChevronUp /> : <FaChevronDown />}
                    </>
                  )}
                </Nav.Link>
                <Collapse in={open3}>
                  <div className="px-2">
                    <Nav.Link onClick={() => navigate("/dashboard/learning-goals")} className="text-white d-flex align-items-center">
                      <FaCommentDots className="me-2" />
                      {"Learning Goals"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/progress-towards-goals")} className="text-white d-flex align-items-center">
                      <FaBell className="me-2" />
                      {"Progress Towards Goals"}
                    </Nav.Link>
                  </div>
                </Collapse>

                <Nav.Link onClick={handleToggle4} className="text-white d-flex align-items-center">
                  <FaThumbsUp className="me-2" onClick={handleToggle} />
                  {open && (
                    <>
                      {"Communication Tools"} {open4 ? <FaChevronUp /> : <FaChevronDown />}
                    </>
                  )}
                </Nav.Link>
                <Collapse in={open4}>
                  <div className="px-2">
                    <Nav.Link onClick={() => navigate("/dashboard/messages")} className="text-white d-flex align-items-center">
                      <FaMessage className="me-2" />
                      {"Messages"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/notifications")} className="text-white d-flex align-items-center">
                      <FaBell className="me-2" />
                      {"Notifications"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/teacher-feedback")} className="text-white d-flex align-items-center">
                      <FaLightbulb className="me-2" />
                      {"Teacher Feedback"}
                    </Nav.Link>
                  </div>
                </Collapse>

                <Nav.Link onClick={() => navigate("/dashboard/support-your-child")} className="text-white d-flex align-items-center">
                  <FaHandshake className="me-2" onClick={handleToggle} />
                  {open && <>{"Support your child"}</>}
                </Nav.Link>

                <Nav.Link onClick={() => navigate("/dashboard/parent-feedback")} className="text-white d-flex align-items-center">
                  <MdFeedback className="me-2" onClick={handleToggle} />
                  {open && "Parent Feedback"}
                </Nav.Link>

                <Nav.Link onClick={handleToggle6} className="text-white d-flex align-items-center">
                  <FaTasks className="me-2" onClick={handleToggle} />
                  {open && (
                    <>
                      {"Administrative Tools"} {open5 ? <FaChevronUp /> : <FaChevronDown />}
                    </>
                  )}
                </Nav.Link>
                <Collapse in={open6}>
                  <div className="px-2">
                    <Nav.Link onClick={() => navigate("/dashboard/account-management")} className="text-white d-flex align-items-center">
                      <FaCalendarAlt className="me-2" />
                      {"Account Management"}
                    </Nav.Link>
                    <Nav.Link onClick={() => navigate("/dashboard/subscription-payments")} className="text-white d-flex align-items-center">
                      <FaPaperPlane className="me-2" />
                      {"Subscription & Payments"}
                    </Nav.Link>
                  </div>
                </Collapse>
              </Nav.Item>
            ) : null}
          </Nav>
        </div>

        <main className="content flex-grow-1 p-0 m-0">
          {/* <div style={{ minHeight: "500px" ,backgroundColor:"blue"}}> */}
          <div style={{ minHeight: "500px" }}>
            <div className="toolbar-spacer" />
            <Outlet />
          </div>
          {/* <Footer/> */}
        </main>

        <style>
          {`
            .nav-link {
               display: flex;
               color: white;
               gap: 10px;
               padding: 9px 10px;
               border-right: 4px solid transparent;
             }
             .nav-link:hover {
               border-right: 5px solid white;
               background: rgb(45, 51, 89);
             }
             .active {
               border-right: 4px solid white;
               background: rgb(45, 51, 89);
             }
            `}
        </style>
      </div>
    </>
  );
};

export default MainLayout;
