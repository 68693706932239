import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Zoom, Slide } from "react-awesome-reveal";
import { FaArrowRight, FaPlayCircle } from "react-icons/fa";
import images from "../../images";

const Home = () => {
  return (
    <section className="home-section text-center" style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }} initial="hidden" animate="visible">
      <Container fluid style={{ paddingLeft: "10%" }}>
        <Row className="d-flex flex-row align-items-center justify-content-center py-5" style={{ height: "100%" }}>
          <Col lg={6} className="d-flex align-items-center text-lg-left text-center mb-4 mb-lg-0">
            <Slide direction="up">
              <div className="header-inner">
                <h1 className="title mb-4" style={{ fontSize: "3rem", fontWeight: "700" }}>
                  Transforming Education for the Future
                </h1>
                <p className="description mb-4" style={{ fontSize: "1.125rem", lineHeight: "1.75rem", fontWeight: "600", color: "#565A5A" }}>
                  Innovative learning solutions for parents and teachers to gain deeper insights into students' language learning journey.
                </p>
                <div className="mt-4">
                  <a href="https://calendly.com/happyplumint/30min" className="btn btn-primary me-2" style={{ backgroundColor: "#7335b7", color: "#ffffff", border: "none" }}>
                    Get Started <FaArrowRight />
                  </a>
                  <a href="/#watchdemo" className="btn btn-light ms-2" style={{ border: "0.5px solid" }}>
                    Watch Demo <FaPlayCircle />
                  </a>
                </div>
              </div>
            </Slide>
          </Col>

          <Col lg={6} className="d-flex justify-content-center align-items-center p-4">
            <Zoom>
              <div className="img-wrap">
                <img src={images.bag} alt="Logo" className="img-fluid mb-3" style={{ maxHeight: "360px" }} />
              </div>
            </Zoom>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Home;
