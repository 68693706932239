// landingPage
import logo from "./landingPage/logo.jpeg";
import bag from "./landingPage/bag.png";
import landing from "./landingPage/landing.PNG";
import hero2 from "./landingPage/Teacher.png";
import hero3 from "./landingPage/welcome.png";
import aboutImg from "./landingPage/aboutsection.jpg";
import one from "./landingPage/one.png";
import two from "./landingPage/two.png";
import three from "./landingPage/three.png";
import four from "./landingPage/four.png";
// pics
import selectLevel from "./pics/selectLevel.png";
import animatedStories from "./pics/animatedStories.png";

// demo_vdos
import studentDemo from "./demo_vdos/student_demo.mp4";
import parentDemo from "./demo_vdos/parent_demo.mp4";
import teacherDemo from "./demo_vdos/teacher_demo.mp4";
// story_vdos
import storyVdo1 from "./demo_vdos/Story 1.mp4";
import storyVdo2 from "./demo_vdos/Story 2.mp4";
import storyVdo3 from "./demo_vdos/Story 3.mp4";
import storyVdo4 from "./demo_vdos/Story 1.mp4";
import storyVdo5 from "./demo_vdos/Story 2.mp4";
// teams
import nina from "./teams/nina.PNG";
import david from "./teams/david.jpeg";
import nick from "./teams/nick.jpg";
import advisor from "./teams/advisor.PNG";
import amrit from "./teams/amrit.jpg";
import manthan from "./teams/manthan_dp.png";
import aayushi from "./teams/aayushi.jpg";
import aditi from "./teams/aditi.jpg";
import rushikesh from "./teams/rushikesh.jpg";
import kartikey from "./teams/kartikey.jpg";
// student_dashboard
import preSchoolImg from "./student_dashboard/preSchoolImg.jpg";
import k3Img from "./student_dashboard/k3Img.jpg";
import middleSchoolImg from "./student_dashboard/middleSchoolImg.jpg";
import highSchoolImg from "./student_dashboard/highSchoolImg.jpg";
import higherEdImg from "./student_dashboard/higherEdImg.jpg";
import adultImg from "./student_dashboard/adultImg.jpg";
import mandarinPic from "./student_dashboard/mandarin.jpg";
import frenchPic from "./student_dashboard/french.jpg";
import spanishPic from "./student_dashboard/spanish.jpg";
import arabicPic from "./student_dashboard/arabic.jpg";
import latinPic from "./student_dashboard/latin.jpg";
import hindiPic from "./student_dashboard/hindi.jpg";
// culture
import china_culture from "./student_dashboard/cultures/china_culture.jpg";
import chinese_new_year from "./student_dashboard/cultures/chinese_new_year.jpg";
import mid_autumn from "./student_dashboard/cultures/mid_autumn.jpg";
import dragon_boat from "./student_dashboard/cultures/dragon_boat.jpg";
import qin_shi_huang from "./student_dashboard/cultures/qin_shi_huang.jpg";
import emperor_wu from "./student_dashboard/cultures/emperor_wu.jpg";
import kangxi from "./student_dashboard/cultures/kangxi.jpg";
import china_living from "./student_dashboard/cultures/china_living.jpg";
import china_daily from "./student_dashboard/cultures/china_daily.jpg";
import french_culture from "./student_dashboard/cultures/french_culture.jpg";
import bastille_day from "./student_dashboard/cultures/bastille_day.jpg";
import fete_musique from "./student_dashboard/cultures/fete_musique.jpg";
import cannes from "./student_dashboard/cultures/cannes.jpg";
import charlemagne from "./student_dashboard/cultures/charlemagne.jpg";
import napoleon from "./student_dashboard/cultures/napoleon.jpg";
import louis_xiv from "./student_dashboard/cultures/louis_xiv.jpg";
import france_living from "./student_dashboard/cultures/france_living.jpg";
import france_daily from "./student_dashboard/cultures/france_daily.jpg";
import spanish_culture from "./student_dashboard/cultures/spanish_culture.jpg";
import la_tomatina from "./student_dashboard/cultures/la_tomatina.jpg";
import bulls_festival from "./student_dashboard/cultures/bulls_festival.jpg";
import semana_santa from "./student_dashboard/cultures/semana_santa.jpg";
import philip_ii from "./student_dashboard/cultures/philip_ii.jpg";
import charles_v from "./student_dashboard/cultures/charles_v.jpg";
import isabella from "./student_dashboard/cultures/isabella.jpg";
import spain_living from "./student_dashboard/cultures/spain_living.jpg";
import spain_daily from "./student_dashboard/cultures/spain_daily.jpg";

//  lessons
import chinese_boy from "./lessons/chinese_boy.jpg";
import french_girl from "./lessons/french_girl.jpg";
import spanish_boy from "./lessons/spanish_boy.jpg";
import cap_boy from "./lessons/login.png";
import park_convo from "./lessons/lesson1.2.JPG";
import whatsurname from "./lessons/whatsurname.webp";
import howru from "./lessons/howru.webp";
import oldyou from "./lessons/oldyou.webp";
import peopleinfamily from "./lessons/peopleinfamily.jpg";
import whoinfam from "./lessons/whoinfam.jpg";
import mk1 from "./lessons/lessons1.png";
import mk2 from "./lessons/lessons2.png";
import mk3 from "./lessons/lessons3.png";
import mk4 from "./lessons/lessons4.png";
import mk5 from "./lessons/lessons5.png";
import mk6 from "./lessons/lessons6.png";
import mk7 from "./lessons/lessons7.png";
import mk8 from "./lessons/lessons8.jpg";
//
import quiz from "./lessons/level1/quiz.jpg";
import tiger from "./lessons/level1/tiger.jpg";
import lion from "./lessons/level1/lion.jpg";
import dog from "./lessons/level1/dog.jpg";
import rabbit from "./lessons/level1/rabbit.jpg";
import elephant from "./lessons/level1/elephant.jpg";
import catt from "./lessons/level1/cat.jpg";
import deer from "./lessons/level1/deer.jpg";
import giraffe from "./lessons/level1/giraffe.jpg";
import monkey from "./lessons/level1/monkey.jpg";
import sheep from "./lessons/level1/sheep.jpg";
import snake from "./lessons/level1/snake.jpg";
import zebra from "./lessons/level1/zebra.jpg";
//
import fruits from "./lessons/level1/fruits.jpg";
import vegetables from "./lessons/level1/vegetables.jpg";
import animals from "./lessons/level1/animals.jpg";
import colors from "./lessons/level1/colors.jpg";
import transportations from "./lessons/level1/transportations.jpg";
import weathers from "./lessons/level1/weathers.jpg";
import shapes from "./lessons/level1/shapes.jpg";
import daysofweek from "./lessons/level1/daysofweek.jpg";
import vocabulary from "./lessons/level1/vocabulary.jpg";

//
import apple from "./lessons/level1/apple.jpg";
import banana from "./lessons/level1/banana.jpg";
import strawberry from "./lessons/level1/strawberry.jpg";
import grape from "./lessons/level1/grape.jpg";
import watermelon from "./lessons/level1/watermelon.jpg";
import mango from "./lessons/level1/mango.jpg";
import pineapple from "./lessons/level1/pineapple.jpg";
import orange from "./lessons/level1/orange.jpg";
import kiwi from "./lessons/level1/kiwi.jpg";
import peach from "./lessons/level1/peach.jpg";
//
import car from "./lessons/level1/car.jpg";
import bus from "./lessons/level1/bus.jpg";
import bicycle from "./lessons/level1/bicycle.jpg";
import train from "./lessons/level1/train.jpg";
import airplane from "./lessons/level1/airplane.jpg";
import ship from "./lessons/level1/ship.jpg";
import subway from "./lessons/level1/subway.jpg";
//
import tomato from "./lessons/level1/tomato.jpg";
import spinach from "./lessons/level1/spinach.jpg";
import cucumber from "./lessons/level1/cucumber.jpg";
import onion from "./lessons/level1/onion.jpg";
import broccoli from "./lessons/level1/broccoli.jpg";
import potato from "./lessons/level1/potato.jpg";
import carrot from "./lessons/level1/carrot.jpg";
//
import red from "./lessons/level1/red.jpg";
import blue from "./lessons/level1/blue.jpg";
import green from "./lessons/level1/green.jpg";
import violet from "./lessons/level1/violet.jpg";
import yellow from "./lessons/level1/yellow.jpg";
import pink from "./lessons/level1/pink.jpg";
import orangee from "./lessons/level1/orangee.jpg";
//
import sunny from "./lessons/level1/sunny.jpg";
import rainy from "./lessons/level1/rainy.jpg";
import cloudy from "./lessons/level1/cloudy.jpg";
import snowy from "./lessons/level1/snowy.jpg";
import windy from "./lessons/level1/windy.jpg";
import stormy from "./lessons/level1/stormy.jpg";
import foggy from "./lessons/level1/foggy.jpg";
import cold from "./lessons/level1/cold.jpg";
//
import circle from "./lessons/level1/circle.png";
import square from "./lessons/level1/square.png";
import rectangle from "./lessons/level1/rectangle.jpeg";
import triangle from "./lessons/level1/triangle.png";
import oval from "./lessons/level1/oval.png";
import rhombus from "./lessons/level1/rhombus.jpg";
import pentagon from "./lessons/level1/pentagon.png";
//
import monday from "./lessons/level1/monday.png";
import tuesday from "./lessons/level1/tuesday.png";
import wednesday from "./lessons/level1/wednesday.jpg";
import thursday from "./lessons/level1/thursday.png";
import friday from "./lessons/level1/friday.png";
import saturday from "./lessons/level1/saturday.png";
import sunday from "./lessons/level1/sunday.jpg";
//
import chinese_dad from "./lessons/chinese_dad.webp";
import chinese_bro from "./lessons/chinese_bro.webp";
import chinese_mom from "./lessons/chinese_mom.webp";
import french_dad from "./lessons/french_dad.jpg";
import french_bro from "./lessons/french_brother.avif";
import french_mom from "./lessons/french_mom.avif";
import spanish_dad from "./lessons/spanish_dad.jpg";
import spanish_bro from "./lessons/spanish_brother.jpg";
import spanish_mom from "./lessons/spanish_mom.jpg";
import cat1 from "./lessons/spanish_cat.jpg";
import cat2 from "./lessons/french_cat.jpg";
import cat from "./lessons/cat.webp";
// animated_vdos
// import mandarinCultureVdo from "./lessons/animated_vdos/Mandarin Culture.mp4";
// import spanishCultureVdo from "./lessons/animated_vdos/Spanish Culture.mp4";
// import frenchCultureVdo from "./lessons/animated_vdos/French Culture.mp4";
import chineseAnimatedVdo from "./lessons/animated_vdos/Chinese_Animation.mp4";
import spanishAnimatedVdo from "./lessons/animated_vdos/Spanish_Animation.mp4";
import frenchAnimatedVdo from "./lessons/animated_vdos/French_Animation.mp4";

const images = {
  logo,
  landing,
  hero2,
  hero3,
  aboutImg,
  bag,
  one,
  two,
  three,
  four,
  selectLevel,
  animatedStories,
  studentDemo,
  parentDemo,
  teacherDemo,
  storyVdo1,
  storyVdo2,
  storyVdo3,
  storyVdo4,
  storyVdo5,
  nina,
  nick,
  david,
  advisor,
  amrit,
  manthan,
  aditi,
  aayushi,
  rushikesh,
  kartikey,
  cap_boy,
  park_convo,
  whatsurname,
  howru,
  oldyou,
  peopleinfamily,
  whoinfam,
  preSchoolImg,
  k3Img,
  middleSchoolImg,
  highSchoolImg,
  higherEdImg,
  adultImg,
  mandarinPic,
  frenchPic,
  spanishPic,
  arabicPic,
  latinPic,
  hindiPic,
  china_culture,
  chinese_new_year,
  mid_autumn,
  dragon_boat,
  qin_shi_huang,
  emperor_wu,
  kangxi,
  china_living,
  china_daily,
  french_culture,
  bastille_day,
  fete_musique,
  cannes,
  charlemagne,
  napoleon,
  louis_xiv,
  france_living,
  france_daily,
  spanish_culture,
  la_tomatina,
  bulls_festival,
  semana_santa,
  philip_ii,
  charles_v,
  isabella,
  spain_living,
  spain_daily,
  chinese_boy,
  french_girl,
  spanish_boy,
  chinese_dad,
  chinese_mom,
  chinese_bro,
  cat1,
  cat2,
  cat,
  french_dad,
  french_bro,
  french_mom,
  spanish_dad,
  spanish_bro,
  spanish_mom,
  mk1,
  mk2,
  mk3,
  mk4,
  mk5,
  mk6,
  mk7,
  mk8,
  quiz,
  fruits,
  vegetables,
  animals,
  colors,
  transportations,
  weathers,
  shapes,
  daysofweek,
  vocabulary,
  tiger,
  lion,
  dog,
  rabbit,
  elephant,
  catt,
  deer,
  giraffe,
  monkey,
  sheep,
  snake,
  zebra,
  apple,
  banana,
  strawberry,
  grape,
  watermelon,
  mango,
  pineapple,
  orange,
  kiwi,
  peach,
  car,
  bus,
  bicycle,
  train,
  airplane,
  ship,
  subway,
  tomato,
  spinach,
  cucumber,
  onion,
  broccoli,
  potato,
  carrot,
  red,
  blue,
  green,
  violet,
  yellow,
  pink,
  orangee,
  circle,
  square,
  triangle,
  rectangle,
  rhombus,
  pentagon,
  oval,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
  sunny,
  rainy,
  cloudy,
  snowy,
  windy,
  stormy,
  foggy,
  cold,
  // mandarinCultureVdo,
  // spanishCultureVdo,
  // frenchCultureVdo,
  chineseAnimatedVdo,
  spanishAnimatedVdo,
  frenchAnimatedVdo,
};

export default images;
