import axios from "axios";

// To create axios api with headers
const getInitialized = (contentType, token, params, responseType) => {
  let api = axios.create({
    withCredentials: true,
    params: params ? params : undefined,
    responseType: responseType ? responseType : undefined,
    headers: {
      "Content-Type": contentType || "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": token ? `Bearer ${token}` : "",
    },
  });
  return api;
};

// GET request
export const getRequest = (url, contentType) => {
  return getInitialized(contentType)
    .get(url, contentType)
    .catch((error) => {
      console.error(error);
    });
};

// GET request by ID
export const getRequestById = (url, id, contentType) => {
  const updatedUrl = `${url}/${id}`;
  return getInitialized(contentType)
    .get(updatedUrl, contentType)
    .catch((error) => {
      console.error(error);
    });
};

export const getRequestByParams = (url, id, contentType, params) => {
  const updatedUrl = `${url}/${id}`;
  return getInitialized(contentType, "", params)
    .get(updatedUrl, contentType)
    .catch((error) => {
      console.error(error);
    });
};

// POST request
export const postRequestMethod = (url, contentType, data) => {
  return getInitialized(contentType).post(url, data);
  // .catch((error) => {
  //   throw new Error(error.response.data.error);
  //   // console.error(error.response.data.error);
  // });
};

export const updateRequestMethod = (url, data, contentType, token) => {
  const api = getInitialized(contentType, token);

  const request = api.put(url, data);

  if (!request) {
    console.error('Invalid method provided for updateRequest');
    return Promise.reject('Invalid method');
  }

  return request
    .catch((error) => {
      console.error(error);
      throw error; // re-throw the error after logging it
    });
};

// PUT request by ID
export const updateRequest = (url, id, data, contentType) => {
  const updatedUrl = `${url}/${id}`; // Append the ID to the URL
  return getInitialized(contentType)
    .put(updatedUrl, data, contentType) // Use put() for HTTP PUT requests or patch() for HTTP PATCH requests
    .catch((error) => {
      console.error(error);
    });
};

// DELETE request by ID
export const deleteRequest = (url, id, contentType) => {
  const deleteUrl = `${url}/${id}`; // Append the ID to the URL
  return getInitialized(contentType)
    .delete(deleteUrl, contentType)
    .catch((error) => {
      console.error(error);
    });
};
